body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-family: 'Orbitron', sans-serif;
  transition: background-color 696969s ease-in-out 0s;
  -webkit-text-fill-color: #0E2150 !important;
}

* {
  image-rendering: -webkit-optimize-contrast;
}

#root {
  height: 100%;
  overflow: hidden;
}